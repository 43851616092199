import React from 'react';
import { Section, SectionAlternate } from 'components/organisms';
import { HowDoYouSay, WhatIsFormigio, WhyFormigio, Hero } from './components';
import {Helmet} from 'react-helmet';

const IndexView = ({ themeMode }) => {
  return (
    <div>
      <Helmet>
        <title>Formigio (tm) - Plan your day and track your goals with the Formigio (tm) daily planner</title>
      </Helmet>
  
      <Hero themeMode={themeMode} />
      <SectionAlternate>
        <WhatIsFormigio />
      </SectionAlternate>
      <Section narrow>
        <WhyFormigio />
      </Section>
      <SectionAlternate>
        <HowDoYouSay />
      </SectionAlternate>
    </div>
  );
};

export default IndexView;

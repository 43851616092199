import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Typography} from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import { SectionHeader } from 'components/molecules';
import { HeroShaped } from 'components/organisms';
import { Trademarked } from 'components/atoms';
import scollingImage from '../../../../assets/images/formigio-app-pages.png';
import Dialog from '@material-ui/core/Dialog';
import YouTubeEmbed from '../../../../components/molecules/YouTubeEmbed/YouTubeEmbed';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  leftSideContent: {
    '& .section-header__cta-container': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .section-header__cta-item-wrapper': {
          width: '100%',
          '&:last-child': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          '& .MuiButtonBase-root': {
            width: '100%',
          },
        },
      },
    }
  },
  heroShaped: {
    '& .hero-shaped__image': {
      backgroundColor: theme.palette.alternate.main,
    },
    [theme.breakpoints.down('sm')]: {
      '& .hero-shaped__image': {
        position: 'relative',
      },
      '& .hero-shaped__wrapper': {
        flexDirection: 'column',
      },
    },
  },
  imageAnimation: {
    background: `url("${scollingImage}")`,
    backgroundRepeat: 'repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: '400px auto',
    animation: `$slideshow 50s linear infinite`,
    width: '600%',
    height: '600%',
    backgroundColor: theme.palette.alternate.dark,
    top: '-25%',
    left: '-100%',
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '800px auto',
    }
  },
  imageAnimationDark: {
    background: `url("${scollingImage}")`,
  },
  '@keyframes slideshow': {
    '0%': {
      transform: 'rotate(-13deg) translateY(-25%)',
    },
    '100%': {
      transform: 'rotate(-13deg) translateY(-80%)',
    },
  },
}));

const Hero = ({ themeMode = 'light', className, ...rest }) => {
  const classes = useStyles();
  const [videoPlayer, setVideoPlayer] = useState(false);

  const title = (
    <Typography variant="h1" component="div" color="primary" className={classes.fontWeight900}>
      <Trademarked styles={{ fontSize: '2rem', top: 6, right: 0 }}>Formigio</Trademarked>
      {' '}Planner
    </Typography>
  );

  const subtitle = <>{'Introducing our '}<Trademarked>Formigio</Trademarked>{' personal planner.'}&nbsp;&nbsp;{'A daily planner and goal tracking tool, that is easy to use and helpful.'}</>

  const leftSideContent = (
    <SectionHeader
      title={title}
      subtitle={subtitle}
      align="left"
      titleProps={{
        variant: 'h1',
        color: 'textPrimary',
      }}
      subtitleProps={{
        variant: 'h6',
        component: 'p'
      }}
      data-aos="fade-right"
      disableGutter
      className={classes.leftSideContent}
      ctaGroup={[
        <Button variant="contained" color="secondary" size="large" component="a" href="https://app.formigio.com/register">
          Start for Free
        </Button>,
        <Button variant="text" color="secondary" size="large" onClick={() => setVideoPlayer(true)}>
          Watch Introduction
          <PlayCircleOutlineIcon/>
        </Button>
      ]}
    />
  );
  return (
    <div className={className} {...rest}>
      <HeroShaped
        className={classes.heroShaped}
        leftSide={leftSideContent}
        rightSide={(
          <div
            className={clsx(
              classes.imageAnimation,
              themeMode === 'dark' ? classes.imageAnimationDark: '',
            )}
          />
        )}
      />
      <Dialog open={videoPlayer} maxWidth="md" onClose={() => setVideoPlayer(false)}>
        <YouTubeEmbed url="https://www.youtube.com/embed/XiyD1NLBRZg"/>
      </Dialog>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Theme mode
   */
  themeMode: PropTypes.string,
};

export default Hero;

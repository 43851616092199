import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {  SectionHeader } from 'components/molecules';
import { Link, Typography } from '@material-ui/core';
import { navigate } from 'gatsby';
import Trademarked from '../../../../components/atoms/Trademarked';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
  marginBottom: {
    marginBottom: '1rem'
  }
}));

const WhyFormigio = ({ className, ...rest }) => {
  const classes = useStyles();
  const title = (<>Why{' '}<Trademarked styles={{ fontSize: '1.7rem', top: 4, right: 0 }}>Formigio</Trademarked>{'?'}</>);
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={title}
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'primary',
          className: classes.fontWeight900,
        }}
      />
      
      <Typography component="p" variant="h6" className={classes.marginBottom}>
        The creator of <Trademarked>Formigio</Trademarked>, Geoff Douglas, has been looking for a tool like this for 10 years. A few years ago he decided to start creating a system for getting things done that would work using paper and pen. That system become The Accomplishment System. A system that can be used to accomplish or do just about anything.
        Learn more{' '}
        <Link
          color="primary"
          href="/about"
          onClick={event => {event.preventDefault(); navigate('/about')}}
          className={classes.listItemButton}
        >
          here
        </Link>.
        As the system matured it was time to use technology to enable the system to be faster and easier to use on the go.
      </Typography>
      <Typography component="p" variant="h6">
        Geoff first created a personal use app that employed the accomplishment system’s review cycles and tracker documents. From there it became a replacement for the daily commitment journal. Now it’s a collection of applications that use centralized data syncing and cloud storage, to allow for multiple devices to be used. From your computer, to your phone, and now any web browser, <Trademarked>Formigio</Trademarked> can be used anywhere to help you keep track of the important things.
      </Typography>
    </div>
  );
};

WhyFormigio.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhyFormigio;
